import { CompanyProfile } from "common/model-profile";
const companyProfileData: CompanyProfile = {
    id: 0,
    geoCode: 0,
    ma: "string",
    ten: "",
    diaChi: 0,
    diaChiText: "",
    giamDoc: "",
    dienThoai: "",
    fax: "",
    taiKhoan: "",
    maSoThue: "",
    keToanTruong: "",
    nguoiGiaoDich: "",
    webSite: "",
    email: "",
    ghiChu: "",
    store1: "",
    store2: "",
    store3: 0,
    store4: 0,
    flagType: 0,
    nguoiDung: 0,
    capDo: 0,
    tgtn: null,
    rateCode: 0,
    accCode: "",
    salesId: "",
    isPrintRate: true,
    isRateNet: true,
    tenDayDu: "",
    guid: "",
    diaChiGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    rcguid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    rateCode2: 0,
    rateCode3: 0,
    nguoiDung2: 0,
    tgtn2: null,
    isVat: true,
    isOta: true,
    resSource: 0,
    kind: 0,
    namThanhLap: null,
    loaiHinhHd: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    thiTruongChinhId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    thiTruongChinh: "",
    thiTruongPtId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    thiTruongPt: "",
    thiTruongHuongToiId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    thiTruongHuongToi: "",
    commission: 0,
    commissionType: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    hotelGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    statusRec: 0
}
export default companyProfileData