import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IdentityApi from "api/identity/identity.api";
import { Login, RootEpic, User } from "common/define-type";
import { ResponseDeparment, ResponseLogin } from "common/define-identity";
import Utils from "common/utils";
import { catchError, filter, map, switchMap,mergeMap } from "rxjs/operators";

type MessageLogin = {
    content: string;
    errorCode?: number
}
interface LoginState {
    loading: boolean;
    isSuccess: boolean;
    user: User | undefined;
    message: MessageLogin | undefined;
    departmentId : number;
}

const initState: LoginState = {
    loading: false,
    isSuccess: false,
    user: undefined,
    departmentId : 1,
    message: undefined
}

const loginSlice = createSlice({
    name: 'login',
    initialState: initState,
    reducers: {
        loginRequest(state, action: PayloadAction<Login>) {
            state.loading = true
        },
        loginSuccess(state, action: PayloadAction<{ user: User, token: string }>) {
            Utils.setLocalStorage('token', action.payload.token);
            // state.loading = false;
            Utils.setLocalStorage("username", action.payload.user.userName);
            Utils.setLocalStorage("user", action.payload.user);
            state.user = action.payload.user
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        getDepartmentRequest(state, action : PayloadAction<string>){
            state.loading = true;
        },
        getDepartmentSuccess(state,action : PayloadAction<ResponseDeparment>){
            state.isSuccess = true;
            state.departmentId = action.payload.departmentId;
        },
        message(state, action: PayloadAction<MessageLogin>) {
            state.message = action.payload;
            state.loading = false
        }
    }
})

const login$: RootEpic = (action$) => action$.pipe(
    filter(loginRequest.match),
    switchMap((re) => {
        return IdentityApi.login(re.payload).pipe(
            mergeMap((res) => {
                if (res && typeof res.errorCode !== 'undefined') {
                    const newRes = res as ResponseLogin;
                    if (newRes.errorCode === 0) {
                        const user: User = { ...newRes.info, setting: newRes.setting };
                        return [loginSlice.actions.loginSuccess({ user, token: newRes.token }),
                            loginSlice.actions.getDepartmentRequest(newRes.token)
                        ];
                    }
                    return [loginSlice.actions.message({
                        content: newRes.msg
                    })];
                }
                return [loginSlice.actions.message({
                    content: 'Lỗi đăng nhập'
                })];
            }),
            catchError(err => [loginSlice.actions.message({ content: 'Lỗi đăng nhập' })])
        )
    })
)
const departmentId$ : RootEpic = (action$) => action$.pipe(
    filter(getDepartmentRequest.match),
    switchMap((re) => {
        return IdentityApi.deparmentId(re.payload).pipe(
            /* eslint-disable-next-line */
            map((res:any) => {
                return loginSlice.actions.getDepartmentSuccess(res);
            }),catchError(err => [loginSlice.actions.message(err)])
        )
    })
)

export const LoginEpics = [
    login$,departmentId$
]
export const {
    getDepartmentRequest,
    loginRequest
} = loginSlice.actions
export const loginReducer = loginSlice.reducer