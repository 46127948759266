/* eslint-disable */
import SYSTEM_CONSTANTS from "common/constants";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import HttpClient from "../http-client";
import { GuestProfile } from "common/model-profile";
import { ListTsRoomPlan } from "common/model-booking";
import { PostBreakShared } from "common/model-inventory";
import { AssignRoom, DataCEditRsvn, ResDataCEditRsvn } from 'common/model-rsvn';
import { EditRoomGroup, InforExtraBed, PostExtraBed, ResCheckInToGroup } from "common/model-rsvn-edit";
import { DMColorType } from "common/shared/dmuccolor.model";
export default class functionPkmApi {
    // static addSharedGuest(payload: any) {
    //     throw new Error("Method not implemented.");
    // }
    static host = "";
    //TransactRoom
    static ActiveStatusTransactRoom(data: any, status: string): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.RSVN_ACTIVE}/${status}`;
        // const api = `http://localhost:2004/${SYSTEM_CONSTANTS.API.RSVN.RSVN_ACTIVE}/${status}`;
        return HttpClient.put(api, data).pipe(map((res) => (res as any) || null));
    }
    //Combine Guest
    static CombineGuestTransctRoom(data: any): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.RSVN_COMBINE_GUEST}`;
        // const api = `http://localhost:2004/${SYSTEM_CONSTANTS.API.RSVN.RSVN_COMBINE_GUEST}`;
        console.log(data);
        return HttpClient.put(api, data).pipe(map((res) => (res as any) || null));
    }

    // get rsvn data and all child data
    static getGuestProfilesRsvnData(data: any): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.RSVN_DATA}/${data}`;
        return HttpClient.get(api, data).pipe(map((res) => (res as any) || null));
    }
    // get transactroom pm
    static getTransactRoomByID(data: any): Observable<any | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.GET_TRANSACTROOM_BY_ID}/${data}`;
        return HttpClient.get(api).pipe(map((res) => (res as any) || null));
    }
    // post transactroom pm
    static addPmToGroup(rSVNId: string, data: any, pmRoomId: string, isCreateGrm: boolean): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.ADD_PM}/${rSVNId}/${pmRoomId}/${isCreateGrm}`;
        return HttpClient.post(api, data, { headers: { pmRoomId: pmRoomId } }).pipe(
            map((res) => (res as any) || null)
        );
    }
    // cancel transactroom pm
    static cancelPmToGroup(roomTypeId: string, rSVNId: string): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.CANCEL_PM}/${rSVNId}`;
        return HttpClient.post(api, `"${roomTypeId}"`, {
            headers: { rSVNId: rSVNId },
        }).pipe(map((res) => (res as any) || null));
    }
    static addGroupMaster(rSVNId: string, trRoomId: string): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.ADD_GROUP_MASTER}/${rSVNId}/${trRoomId}`;
        return HttpClient.post(api, "data", {
            headers: { trRoomId: trRoomId },
        }).pipe(map((res) => (res as any) || null));
    }
    static unsetGroupMaster(rSVNId: string, trRoomId: string): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.UNSET_GROUP_MASTER}/${rSVNId}/${trRoomId}`;
        return HttpClient.post(api, {}).pipe(map((res) => (res as any) || null));
    }
    static addSharedGuest(data: any): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.ADD_SHARED_GUEST}`;
        return HttpClient.put(api, data).pipe(map((res) => (res as any) || null));
    }
    static addCommentTransactionRoom(trRoomId: string, data: string): Observable<string | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.ADD_COMMENT_TRANSACTROOM}/${trRoomId}`;
        return HttpClient.post(api, `"${data}"`).pipe(
            map((res) => (res as string) || null)
        );
    }
    static setGroupStatusRsvn(data: any, IsIncludeChild: boolean, isCheckin: boolean): Observable<string | null> {
        // const api = `http://localhost:2004/${SYSTEM_CONSTANTS.API.RSVN.SET_STATUS_GROUP_RSVN}/${status}`;
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.SET_STATUS_RSVN}/${IsIncludeChild}/${isCheckin}`;
        return HttpClient.post(api, data).pipe(
            map((res) => (res as string) || null)
        );
    }
    
    static confirmTSRoom(data: any): Observable<unknown | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.CONFIRM}/${data}`;
        return HttpClient.post(api, true).pipe(map((res) => (res as any) || null));
    }
    static searchGroupListRoomPlan(data: any, listRoomGuid: string[]): Observable<ListTsRoomPlan[] | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.RSVN_SEARCH_ROOM_PLAN}`;
        //const api = `http://localhost:2104/${SYSTEM_CONSTANTS.API.RSVN.RSVN_SEARCH_ROOM_PLAN}`;
        return HttpClient.post(api, data, { headers: { 'lstRooms': listRoomGuid } }).pipe(
            map((res) => (res as ListTsRoomPlan[]) || null)
        );
    }
    static breakShared(data: PostBreakShared, isValidated: boolean): Observable<any | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.BREAK_SHARED}`;
        //const api = `http://localhost:2104/${SYSTEM_CONSTANTS.API.RSVN.BREAK_SHARED}`;
        return HttpClient.post(api, data, { headers: { 'IsValidated': isValidated } }).pipe(
            map((res) => (res as ListTsRoomPlan[]) || null)
        );
    }
    static assignRoom(data: AssignRoom[]): Observable<any | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM_GROUP.ASSIGNROOM}`;
        return HttpClient.post(api, data).pipe(map((res) => (res as any) || null));
    }
    static getDataTsRoomEditByGuid(tsRoomGuid: string): Observable<ResDataCEditRsvn | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM_GROUP.TSROOM_EDIT_BY_ID}/${tsRoomGuid}`;
        return HttpClient.get(api).pipe(
            map((res) => (res as ResDataCEditRsvn) || null)
        );
    }
    static editGroupReservation(data: DataCEditRsvn, guid: string): Observable<string | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.UPDATE_GROUP_RESERVATION}/${guid}`;
        return HttpClient.put(api, data).pipe(
            map((res) => (res as string) || null)
        );
    }
    static editDataTsRoomEditByGuid(data: DataCEditRsvn, tsRoomGuid: string, isExtraGuest: boolean): Observable<string | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.UPDATE_RESERVATION}/${tsRoomGuid}/${isExtraGuest}`;
        return HttpClient.put(api, data).pipe(
            map((res) => (res as string) || null)
        );
    }
    static editRoomGroup(data: EditRoomGroup, tsRoomGuid: string, isExtraGuest: boolean): Observable<string | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM_GROUP.UPDATE_ROOM_GROUP}/${tsRoomGuid}/${isExtraGuest}`;
        return HttpClient.put(api, data).pipe(
            map((res) => (res as string) || null)
        );
    }

    static unAssignRoom(transactIdList: string[]): Observable<any | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM_GROUP.UNASSIGN}`;
        return HttpClient.get(api, { headers: { transactionGuids: transactIdList } }).pipe(
            map((res) => (res as any) || null)
        );
    }

    static updateGroupRSVN(data: any, rsvnid: string): Observable<string | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.UPDATE_GROUP_RSVN}/${rsvnid}`;
        return HttpClient.put(api, data).pipe(
            map((res) => (res as string) || null)
        );
    }
    static updateTransactRoom(transactionGuid: string[], value: boolean): Observable<string | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.UPDATE}`;
        return HttpClient.put(api, { transactionGuid, doNotMove: value }).pipe(
            map((res) => (res as string) || null)
        );
    }

    static getDMucColorType(): Observable<DMColorType[] | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.DMUC_COLOR_TYPE}`;
        return HttpClient.get(api).pipe(
            map((res) => (res as DMColorType[]) || null)
        );
    }
    static getDMucTuyChonAmoutBed(data: PostExtraBed): Observable<InforExtraBed | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.DMUC_TUY_CHON_EXTRABED}`;
        return HttpClient.post(api, data).pipe(
            map((res) => (res as any) || null)
        );
    }
    static updateSmallEditGroup(data: any, rsvnId: string, isChild: boolean): Observable<InforExtraBed | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.SMALL_FUNC_EDIT_GROUP}/${rsvnId}/${isChild}`;
        return HttpClient.put(api, data).pipe(
            map((res) => (res as any) || null)
        );
    }
    static checkInToRSVN(listTsRoom: string[], data: DataCEditRsvn, isExtraGuest: boolean): Observable<InforExtraBed | null> {
        //const api = `http://localhost:2104/${SYSTEM_CONSTANTS.API.RSVN.CHECK_IN_TO_RSVN}/${isExtraGuest}`;
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.CHECK_IN_TO_RSVN}/${isExtraGuest}`;
        return HttpClient.put(api, data, { headers: { 'transactionGuids': listTsRoom } }).pipe(
            map((res) => (res as any) || null)
        );
    }
    static checkInToGroup(rsvnId: string, businessDate: string): Observable<ResCheckInToGroup | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.CHECK_IN_TO_GROUP}/${rsvnId}`;
        return HttpClient.put(api, businessDate).pipe(
            map((res) => (res as any) || null)
        );
    }

    static EditGuestInTSRoom(oldGuestId: string, newGuestId: string): Observable<any | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.EDIT_GUEST}/${oldGuestId}/NewGuestId/${newGuestId}`;
        return HttpClient.put(api, {headers: {oldGuestId, newGuestId}}).pipe(
            map((res) => (res as any) || null)
        );
    }

    static EditCompany(oldCompanyId: string, newCompanyId: string): Observable<any | null> {
        const api = `${functionPkmApi.host}/${SYSTEM_CONSTANTS.API.RSVN.TRANSACTROOM.EDIT_COMPANY}/${oldCompanyId}/NewCompanyAgentGuid/${newCompanyId}`;
        return HttpClient.put(api, {headers: {oldCompanyId, newCompanyId}}).pipe(
            map((res) => (res as any) || null)
        );
    }
}
