/* eslint-disable */
import SYSTEM_CONSTANTS from 'common/constants';
import {  Login } from 'common/define-type';
import { ResponseDeparment, ResponseLogin } from 'common/define-identity';
import { Observable } from 'rxjs/internal/Observable';
import { map } from "rxjs/operators";
import HttpClient from "../http-client";
export default class IdentityApi {
    static host = '';
    static login(data: Login): Observable<ResponseLogin | null> {
        return HttpClient.post(`${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.LOGIN}`, data).pipe(
            map((res) => res as ResponseLogin || null)
        );
    }
    
    static deparmentId(token : any): Observable<ResponseDeparment | null>{
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.LISTHOTEL.DEPARTMENT}`;
        return HttpClient.get(api,{ headers: { Authorization : `Bearer ${token}` } }).pipe(
            map((res) => res as ResponseDeparment || null)
        )
    }
}