/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import HotelConfigApi from "api/hcfg/hcfg.api";
import { RootEpic } from "common/define-type";
import { HotelConfigInfo } from "common/shared/hotelconfig.model";
import Utils from "common/utils";
import { filter, map, switchMap } from "rxjs/operators";

interface HotelConfigState{
    hcfgInfo: HotelConfigInfo,
    loading: boolean,
    businessDate: string
}

const initState: HotelConfigState = {
    hcfgInfo: {
        paymentMethods: [],
        fixCharges: [],
        packages: [],
        channels: [],
        markets: [],
        origins: [],
        sources: [],
        rsvnTypes: [],
        specials: [],
        rateCodes: []
    },
    businessDate: '',
    loading: false
}

const hotelConfigSlice = createSlice({
    name: 'hotelConfig',
    initialState: initState,
    reducers: {
        getHcfgInfoRequest(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        setHcfgInfo(state, action: PayloadAction<HotelConfigInfo>) {
            state.hcfgInfo = action.payload;
        },
        bussinessDateReq(state, action: PayloadAction<string>){
            state.loading = true
        },
        bussinessDateSuccess(state, action: PayloadAction<string>){
            state.businessDate = Utils.convertMiddleDate(action.payload)
        }
    }
})

const getbussinessDate$: RootEpic = (action$) => action$.pipe(
    filter(bussinessDateReq.match),
    switchMap((action) => {
        return HotelConfigApi.getBussinessDate(action.payload).pipe(
            map((res: any) => {
                const convertBusinessDate = Utils.convertToVNTimeZoneMbyMoment(res);
                return hotelConfigSlice.actions.bussinessDateSuccess(convertBusinessDate);
            }
            )
        )
    }
    )
)
const getHotelConfigInfo$: RootEpic = (action$) => action$.pipe(
    filter(getHcfgInfoRequest.match),
    switchMap((action) => {
        return HotelConfigApi.getHcfgInfo(action.payload).pipe(
            map((res: any) => {
                return hotelConfigSlice.actions.setHcfgInfo(res);
            }
            )
        )
    }
    )
)

export const HotelConfigEpic = [
    getHotelConfigInfo$,
    getbussinessDate$
]

export const {
    getHcfgInfoRequest,
    setHcfgInfo,
    bussinessDateReq
} = hotelConfigSlice.actions

export const hotelConfigReducer = hotelConfigSlice.reducer