import { notification } from 'antd';
import { NotificationStatus } from 'common/enum/shared.enum';

const openNotification = (type: NotificationStatus, message: string | null, description: string | null) : void => {
    notification[type]({
        message: `${message}`,
        description: `${description}`,
        style : { borderRadius : 6, top : "8vh" }
    });
};

export default openNotification;