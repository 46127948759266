
const SYSTEM_CONSTANTS = {
    API: {
        LISTHOTEL: {
            DEPARTMENT: 'api/Employee/me',
            HOTEL: 'api/OperationCenter/me'
        },
        IDENTITY: {
            LOGIN: 'api/Account/login'
        },
        RSVN: {
            RESERVATION: 'api/RSVN',
            RSVN_QUERYPARAM: 'api/RSVN/QueryParam',
            RESERVATED_ROOMS: 'api/RSVN/ReservatedRooms/roomtypeIds',
            RSVN_ACTIVE: "api/TransactRoom/setStatusTransactRoom",
            GET_BOOKING_BY_RSVNID: "api/RSVN/EditData/RsvnId",
            SET_STATUS_RSVN: "api/TransactRoom/Status/multi",
            STATISTIC: {
                GET_ROOM_AND_GUEST_BY_DATE: "api/Statistic/FrontDesk",
                REVENUE_DAY: "api/Statistic/RevenueInDays",
                SOURCE_MARKET: "api/Statistic/RevenueBySourceMarket"
            },
            UPDATE_GROUP_RSVN: "api/RSVN/Edit/RsvnId",
            TRANSACTROOM: {
                GET_TRS_INFO: 'api/TransactRoom/{id}',
                UPDATE: '/api/TransactRoom',
                CONFIRM: "api/TransactRoom/Confirm",
                CONFIRM_BY_RSVN: "api/TransactRoom/ConfirmsByRsvn",
                STATUS: "api/TransactRoom/Confirm",
                STATUS_BY_RSVN: "api/TransactRoom/StatusByRsvn",
                GET_TRANSACTROOM_BY_ID: "api/TransactRoom",
                ADD_PM: "api/TransactRoom/TransactRoom/AddPM",
                CANCEL_PM: "api/TransactRoom/TransactRoom/CancelPM",
                ADD_GROUP_MASTER: "api/TransactRoom/TransactRoom/AddGrMaster",
                UNSET_GROUP_MASTER: "api/TransactRoom/TransactRoom/UnsetGrMaster",
                ADD_SHARED_GUEST: "api/TransactRoom/AddShareGuest",
                ADD_COMMENT_TRANSACTROOM: "api/TransactRoom/Comment",
                CHANGE_STATUS_TRANSACTROOMS: "api/TransactRoom/Status/multi",
                CHANGE_MAIN_GUEST: "api/TransactRoom/MainGuest/OldMainTrRoom/OldMainTrRoomId/NewMainTrRoom/NewMainTrRoomId",
                GET_TRANSACTROOM_BY_STATUS: "api/TransactRoom/TransactRoomByStatus",
                GET_GUEST_HISTORY_YEAR: "api/TransactRoom/GetGuestHistoryYear",
                GET_COMPANY_AGENT_HISTORY_YEAR: "api/TransactRoom/GetCompanyOrAgentHistoryYear",
                EDIT_GUEST: "api/TransactRoom/OldGuestId",
                EDIT_COMPANY: "api/TransactRoom/OldCompanyAgentGuid",
                GET_SHARESGUEST_TRSROOM: "api/TransactRoom/SharedGuestByTsRoom/tsRoomId"
            },
            TRANSACTROOM_GROUP: {
                TSROOM_EDIT_BY_ID: "api/TransactRoomsGroups/EditRsvn/trsRoomID",
                UNASSIGN: "api/TransactRoomsGroups/Unassign",
                ASSIGNROOM: "api/TransactRoomsGroups/AssignRoom",
                UPDATE_ROOM_GROUP: "api/TransactRoomsGroups/EditTrRoom/trsRoomID",
                GET_TRANSACTION_ROOM_UNASSIGN: "/api/TransactRoomsGroups/UnassignedRoomList",
                STAT_BOOKING: "api/TransactRoomsGroups/StateBookingGroup",
                USER_ROOM_DETAIL: "api/TransactRoomsGroups/UsedRoomDetail"
            },
            RSVN_COMBINE_GUEST: 'api/TransactRoom/CombineGuest',
            RSVN_DATA: 'api/RSVN/EditData/RsvnId',
            RSVN_SEARCH_ROOM_PLAN: 'api/TransactRoomsGroups/RoomLists',
            BREAK_SHARED: 'api/TransactRoomsGroups/BreakShare',
            WALK_IN: 'api/RSVN/Walkin',
            UPDATE_RESERVATION: "api/RSVN/EditRsvn/trsRoomID",
            UPDATE_GROUP_RESERVATION: "api/RSVN/Edit/MultiTsRoom",
            DMUC_COLOR_TYPE: 'RSVN/DMUC/ColorType',
            COUNT_OF_RSVN: 'api/RSVN/Count/Hotel/{hotelId}',
            DMUC_TUY_CHON_EXTRABED: 'RSVN/DMUC/DMucTuyChon/extraBedUsed',
            SMALL_FUNC_EDIT_GROUP: 'api/RSVN/SmallFuncEditGroup/rsvnId',
            CHECK_IN_TO_RSVN: "api/RSVN/CheckInToRSVN",
            CHECK_IN_TO_GROUP: "api/RSVN/CheckInToGroup",
            LIST_GUEST_GUID_PROFILE: "api/RSVN/GuestId/rsvnId"
        },
        INV: {
            ROOM: 'api/INV/Room/All',
            EDITROOM: 'api/INV/Room',
            ROOM_TYPE: 'api/INV/RoomType',
            ROOM_TYPE_INFO: 'api/INV/RoomType/Infos',
            ALL_ROOMS_IN_ROOMTYPE: 'api/INV/RoomType/Room',
            ROOMS_IN_ROOMTYPES: 'api/INV/RoomsInRoomTypes',
            FLOOR_BY_HOTEL: 'api/INV/Floors',
            SEARCH_BY_ROOM_NO: 'api/INV/Room/SearchByRoomNo',
            AVAILABLE_ROOMS: 'api/INV/AvailableRoom',
            STATISTIC: {
                HOUSE_KEEPING: "api/Statistic/HouseKeeping"
            }
        },
        PRF: {
            GUEST_PROFILES: 'api/PRF/GuestProfiles',
            GUEST_NEW_PROFILES: 'api/PRF/GuestProfile',
            DELETE_GUEST: "api/PRF/GuestProfile",
            COMPANY_PROFILE: 'api/PRF/CompanyProfile',
            GUEST_PROFILES_SEARCH: 'api/PRF/GuestProfile/Search',
            POST_MAIN_AND_MEMBER_PROFILES: 'api/PRF/GuestProfile/MainAndMembers',
            GET_PROFILE_BY_GUEST_ID: 'api/PRF/GuestProfile',
            COMPANY_PROFILE_GUID: 'api/PRF/CompanyProfile/hotelGuid',
            CREATE_PROFILE_BY_COUNT_GUEST: 'api/PRF/GuestProfiles',
            LIST_GUEST_PROFILES: 'api/PRF/ListGuestProfiles',
            PRF_COMPONENT: {
                TITLE: 'api/PRFComponents/Title',
                NATIONALITY: 'api/PRFComponents/Nationality',
                GUEST_TYPES: 'api/PRFComponents/Nationality',
                GUEST_DETAIL_OPTIONS: 'api/PRFComponents/GuestDetailOptions',
                GUEST_MORE_DETAILS: 'api/PRFComponents/GuestMoreDetailOptions',
                COMPANY_MORE_CUSTOMER_MARKET: 'api/PRFComponents/CustomerMarket',
                COMPANY_MORE_COMMISSION_TYPE: 'api/PRFComponents/CommissionType',
                COMPANY_MORE_LOAI_HINH_HD: 'api/PRFComponents/LoaiHinhHD',
            }
        },
        HCFG: {
            RESERVATION_HCFG: 'api/HCFG/HCFGInfo',
            RSVN_RATECODE: 'api/HCFG/RateCodes',
            RSVN_PAYMENT: 'api/HCFG/PaymentMethods',
            RSVN_TYPE: "api/HCFG/RSVNTypes",
            FIX_CHARGES: "api/HCFG/FixCharges",
            DMUC_MINIBAR: "api/HCFG/GetAllDmucMiniBar",
            DMUC_HANGHOADICHVU: "api/HCFG/GetAllDmucHangHoaDichVu",
            DMUC_QUANAOGIATLA: "api/HCFG/DmucHSKPQuanAoGiatLa",
            DMUC_OUTLET: "api/HCFG/DmucChOutLet",
            DMUC_DEPARTMENT: "api/HCFG/DmucDepartment",
            OPERATOR: {
                HOTEL: 'HCFG/Operator/Hotel',
                GET_BUSSINESS_DATE: "HCFG/Operator/BusinessDate"
            }
        },
        CASHIER: {
            GROUP_FOLIO: "api/GroupFolio",
            GET_FOLIO: "api/Folio",
            GET_DATA_FOLIO: "api/Folio/FilterFolio",
            POSTING_MINI_BAR: "api/Folio/PostingMiniBar",
            POSTIN_LAUNDRY: "api/Folio/PostingLaundry",
            NEW_VOUCHER_MINIBAR: "NewestVoucherMiniBar",
            NEW_VOUCHER_LAUNDRY: "NewestVoucherLaundry",
            NEW_VOUCHER_FOOD_OTHERSERVICE: "api/Folio/NewestVoucherFolioId",
            POST_FOODANDBEVERAGE: "api/Folio/PostingFoodAndBeverage",
            POST_OTHERSERVICE: "api/Folio/PostingOrtherService",
            POST_ROOM_CHARGE: "api/Folio/PostingRoomCharge",
            POST_ADVANCE_ROOM_CHARGE: "api/Folio/PostingAdvanceRoomCharge",
            POST_REBATE: "api/Folio/PostingRebate",
            POST_SPLIT: "api/Folio/SplitFolio",
            POST_CORRECTION: "api/Folio/Correction",
            POST_MOVE: "api/Folio/MoveFolio",
            COMBINE_FOLIO: "api/Folio/CombineFolio",
            AMOUNT_ROOMCHAT: "api/Folio/AmountAndRoomChat"
        }
    }
}

export default SYSTEM_CONSTANTS