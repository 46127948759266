import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CashierAPI from "api/cashier/cashier.api";
import { ITableMinibarAndLaundry } from "common/cashier/model-cashier";
import { RootEpic } from "common/define-type";
import { catchError, filter, map, switchMap } from "rxjs/operators";

interface FolioState {
    loading: boolean,
    message: string,
    listGoodsMinibar: ITableMinibarAndLaundry[],
    listGoodsLaundry: ITableMinibarAndLaundry[],
    voucherNumberFolio: number
}

const initAppState: FolioState = {
    loading: false,
    message: "",
    listGoodsMinibar: [],
    listGoodsLaundry: [],
    voucherNumberFolio: 0
}

const folioSlice = createSlice({
    name: 'folioSlice',
    initialState: initAppState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload 
        },
        message(state, action: PayloadAction<string>) {
            state.message = action.payload
        },
        setGoodsMinibar(state, action: PayloadAction<ITableMinibarAndLaundry[]>){
            state.listGoodsMinibar = action.payload
        },
        setGoodsLaundry(state, action: PayloadAction<ITableMinibarAndLaundry[]>){
            state.listGoodsLaundry = action.payload
        },
        fetchVoucherNumberFolio(state, action: PayloadAction<string>){
            state.loading = true
        },
        getVoucherNumberFolio(state, action: PayloadAction<number>){
            state.voucherNumberFolio = action.payload
        },
        
    }
})

const fetchNewestVoucherService$: RootEpic = (action$) => action$.pipe(
    filter(fetchVoucherNumberFolio.match),
    switchMap((action) => {
        return CashierAPI.getVoucherNumberFolio(action.payload).pipe(
            map((res) => {
                return folioSlice.actions.getVoucherNumberFolio(res)
            }), catchError((err) => {
                return [folioSlice.actions.message(err.message)]
            })
        )
    })
)

export const FolioEpics = [
    fetchNewestVoucherService$
]

export const {
    setGoodsMinibar,
    setGoodsLaundry,
    fetchVoucherNumberFolio,
} = folioSlice.actions;
export const folioReducer = folioSlice.reducer