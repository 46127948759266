import React from "react";
import { Redirect, Route } from "react-router-dom";
import Utils from "common/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsPrivateRouter = Props & { children: JSX.Element, path: string };

function CPrivateRoute({ children, path, ...rest }: PropsPrivateRouter): JSX.Element {
    const user = Utils.getValueLocalStorage("username")
    return (
        <Route
            {...rest}
            path={path}
            render={(props) => (user !== null) ? children : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )}
        />
    );
}

export default CPrivateRoute;